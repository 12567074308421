:root {
  --font-family: 'Poppins', sans-serif, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue;

  --black: #000;
  --white: #fff;
  --primary:#B660E1;
  --purple-branding: #a336d9;
  --purple-darken: #151027;
  --purple-darken2: #561d73;
  --gray: #dad6dc;
  --gray2: #d0cfd4;
  --gray3: #d7d6d9;
  --yellow: #fbc02c;

  --color-text: #424242;
  --color-bg: #190e17;
  --color-link: #999;
  --color-link-hover: #fff;
  --color-primary: #88e7c4;
  --color-secondary: #c5517d;
  --color-description: #3b343a;

  --color-background: rgba(21, 16, 39, 0.8);
}
