@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@import '/src/styles/variable.scss';
@import '/src/styles/typography.scss';

html {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
}

body {
  width: 100%;
  font-size: 16px;
  font-family: var(--font-family);
  background-color: var(--purple-darken);
  color: var(--white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  overflow-x: hidden;

  &.ReactModal__Body--open {
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
  }

  .logo_svg,
  .header-contact-us,
  .footer-content {
    transition: all 1s ease;
  }

  .logo_svg {
    filter: invert(95%) sepia(95%) saturate(0%) hue-rotate(163deg)
      brightness(106%) contrast(104%);
  }

  .footer-content {
    .socials a {
      img {
        display: none;
      }
      .dark {
        display: initial;
      }
    }
  }

  &.light {
    .logo_svg {
      filter: none;
    }

    .footer-content {
      .socials a {
        img {
          display: initial;
        }
        .dark {
          display: none;
        }
      }
    }

    .header-contact-us {
      color: var(--purple-branding);
      border-color: var(--purple-branding);

      &:hover {
        color: #fff;
        background-color: var(--purple-branding);
      }
    }

    .footer-content {
      svg {
        filter: invert(49%) sepia(12%) saturate(301%) hue-rotate(213deg)
          brightness(88%) contrast(92%);
      }
    }
  }
}

#__next {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

button {
  font-family: var(--font-family);
  cursor: pointer;
  padding: 0;
  transition: 0.4s ease-in-out;
  outline: none;
}

p {
  margin: 0;
}

img {
  object-fit: cover;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px var(--purple-darken) inset;
  -webkit-text-fill-color: var(--white);
}

// ***
// MODIFIER
// ***

.screen-reader {
  width: 1px;
  height: 1px;
  padding: 0;
  border: none;
  position: absolute;
  overflow: hidden;
}

.layout-wrap {
  max-width: 1172px;
  padding: 20px 16px;
  margin: 0 auto;
  width: 100%;
}

.title-section {
  position: relative;
  width: fit-content;
  padding: 0 99px;
  margin: 0 auto 70px;
  font-size: 32px;
  font-weight: 600;

  @media screen and (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 40px;
    padding: 0;
  }
}

.button {
  line-height: 52px;
  color: var(--white);
  border-radius: 100px;
  padding: 0;
  display: inline-block;
  transition: background 0.1s ease-in-out;

  &-outline {
    border: 2px solid var(--white);

    &:hover {
      background-color: var(--white);
      color: var(--purple-darken);

      svg path {
        fill: var(--purple-darken);
      }
    }
  }
}

.button-link {
  display: inline-block;
  border: 2px solid var(--white);
  padding: 16px 55px;
  border-radius: 100px;
  font-size: 16px;
  transition: background 0.1s ease-in-out;

  &:hover {
    background-color: var(--white);
    color: var(--purple-darken);
  }
}

.stop-scrolling {
  // @media screen and (min-width: 1200px) {
  height: 100vh;
  overflow: hidden;
  // }
}

// react-modal
.ReactModal__Overlay {
  z-index: 999;
  height: 100%;
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }
}

.ReactModal__Content {
  overscroll-behavior-y: contain;
  overflow-y: auto;
}

.disabled-link {
  pointer-events: none;
  opacity: 0.4;
  cursor: default;
}
