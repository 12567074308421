// ***
// MIXIN
// ***
@mixin typography(
  $fontSize: 12px,
  $fontWeight: 400,
  $lineHeight: 0,
  $letterSpacing: 0
) {
  font-weight: $fontWeight;
  font-size: $fontSize;

  @if $lineHeight != 0 {
    line-height: $lineHeight;
  }

  @if $letterSpacing != 0 {
    letter-spacing: $letterSpacing;
  }
}

@mixin line-limit($limit: 1) {
  display: -webkit-box;
  line-height: initial;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  -webkit-line-clamp: $limit;
}

@mixin weight($weight: 500) {
  font-weight: $weight;
}

// ***
// TITLE
// ***
.title1 {
  @include typography(32px, 600);
}
.title2 {
  @include typography(20px, 600);
}

.title-wrap {
  @include typography(24px, 600);

  @media screen and (min-width: 769px) {
    @include typography(48px, 500);
  }
}

// ***
// NORMAL TEXT
// ***
.typo1 {
  @include typography(16px, 400);
}
.typo2 {
  @include typography(14px, 400);
}

// ***
// FONT-WEIGHT
// ***
.font-normal {
  @include weight(400);
}
.font-medium {
  @include weight(500);
}
.font-semibold {
  @include weight(600);
}

// ***
// MODIFIER
// ***
.text {
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
  &-uppercase {
    text-transform: uppercase;
  }
  &-capitalize {
    text-transform: capitalize;
  }
  &-break-all {
    word-break: break-all;
  }

  // color
  &-white {
    color: var(--white);
  }
  &-gray {
    color: var(--gray);
  }
  &-gray2 {
    color: var(--gray2);
  }
  &-gray3 {
    color: var(--gray3);
  }
  &-yellow {
    color: var(--yellow);
  }
  &-branding {
    color: var(--purple-branding);
  }
}

.one-line {
  @include line-limit(1);
}
